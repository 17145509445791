import React from 'react';
import { Sparklines, SparklinesLine, SparklinesSpots, SparklinesReferenceLine } from 'react-sparklines';
import { CurrencyCardSparklineProps } from './interfaces';

const CardSparkline = function (props: CurrencyCardSparklineProps) {
  const shouldShowRed = (value: number): boolean => {
    return value >= 0;
  };
  const sparklineStyle = (value: number): string => {
    return shouldShowRed(value) ? '#059669' : '#dc2626';
  };
  return (
    <>
      {props.sparkline?.price.length ? (
        <Sparklines data={props.sparkline?.price} width={props.width} height={props.height}>
          <SparklinesLine color={sparklineStyle(props.priceChangePercentageIn7Days as number)} style={props.style} />
          {props.shouldShowReferenceLine ? (
            <SparklinesReferenceLine
              type="median"
              style={{
                stroke: sparklineStyle(props.priceChangePercentageIn7Days as number),
                strokeOpacity: 0.5,
                strokeDasharray: '2, 2',
              }}
            />
          ) : (
            <></>
          )}
          {props.shouldShowSparklineSpots ? (
            <SparklinesSpots
              size={1.5}
              style={{
                stroke: sparklineStyle(props.priceChangePercentageIn7Days as number),
                strokeWidth: 1,
                fill: 'white',
              }}
            />
          ) : (
            <></>
          )}
        </Sparklines>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(CardSparkline);
