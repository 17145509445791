import React from 'react';
import { PRICE_TYPES, SORT_TYPES } from './utils/constants';
import { Option } from 'react-dropdown';

const defaultPrice = { label: PRICE_TYPES[0].currency, value: PRICE_TYPES[0].code };
const defaultSort = SORT_TYPES[0];

type CoinContextType = {
  priceType: Option;
  sortType: Option;
  currentPage: number;
  fetchSparkline: boolean;
  setPriceType: (priceType: Option) => void;
  setSortType: (sortType: Option) => void;
  setCurrentPage: (currentPage: number) => void;
  setFetchSparkline: (fetchSparkline: boolean) => void;
};

export const CoinContext = React.createContext<CoinContextType>({
  priceType: defaultPrice,
  sortType: defaultSort,
  currentPage: 1,
  fetchSparkline: false,
  setPriceType: () => {},
  setSortType: () => {},
  setCurrentPage: () => {},
  setFetchSparkline: () => {},
});

export class CoinContextProvider extends React.Component {
  state = {
    priceType: defaultPrice,
    sortType: defaultSort,
    currentPage: 1,
    fetchSparkline: false,
  };

  render() {
    return (
      <CoinContext.Provider
        value={{
          priceType: this.state.priceType,
          sortType: this.state.sortType,
          currentPage: this.state.currentPage,
          fetchSparkline: this.state.fetchSparkline,
          setPriceType: (priceType: Option) => {
            this.setState({
              priceType,
            });
          },
          setSortType: (sortType: Option) => {
            this.setState({
              sortType,
            });
          },
          setCurrentPage: (currentPage: number) => {
            this.setState({
              currentPage,
            });
          },
          setFetchSparkline: (fetchSparkline: boolean) => {
            this.setState({
              fetchSparkline,
            });
          },
        }}
      >
        {this.props.children}
      </CoinContext.Provider>
    );
  }
}
