import React, { useContext, useEffect, useState } from 'react';
import { Option } from 'react-dropdown';
import Switch from 'react-switch';
import { CoinContext } from '../../CoinContext';
import Carousel from '../../components/Carousel';
import ContactSection from '../../components/ContactSection';
import CurrencyCard from '../../components/CurrencyCard';
import CardsSkeleton from '../../components/CardsSkeleton';
import Pagination from '../../components/Pagination';
import PriceTypeDropdown from '../../components/PriceTypeDropdown';
import SortDropdown from '../../components/SortDropdown';
import { useInterval } from '../../hooks';
import { getCoinsMarketData } from '../../services/';
import { CoinMarketData } from '../../services/Coins/types';
import { POLL_REFRESH_DATA, PRICE_TYPES } from '../../utils/constants';

export function Dashboard() {
  const context = useContext(CoinContext);
  const [coins, setCoins] = useState<CoinMarketData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(context.currentPage);
  const [priceType, setPriceType] = useState<Option>(context.priceType);
  const [sortType, setSortType] = useState<Option>(context.sortType);
  const [fetchSparkline, setFetchSparkline] = useState<boolean>(context.fetchSparkline);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  async function fetchData(currentPage: number, currency: string, sortBy: string, fetchSparkline: boolean) {
    if (!isFetching) {
      const data = await getCoinsMarketData(currentPage, currency, sortBy, fetchSparkline);
      setCoins(data);
    }
  }

  useInterval(
    () => fetchData(currentPage, priceType.value.toLowerCase(), sortType.value, fetchSparkline),
    POLL_REFRESH_DATA
  );

  useEffect(() => {
    let isMounted = true;
    setIsFetching(true);
    getCoinsMarketData(currentPage, priceType.value.toLowerCase(), sortType.value, fetchSparkline).then((data) => {
      if (isMounted) {
        setCoins(data);
        setIsFetching(false);
      }
    });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, priceType, sortType, fetchSparkline]);

  function handlePageChange(item: Record<string, number>) {
    setCoins([]);
    context.setCurrentPage(item.selected + 1);
    setCurrentPage(item.selected + 1);
  }

  function handlePriceTypeChange(type: Option) {
    setCoins([]);
    context.setPriceType(type);
    setPriceType(type);
  }

  function handleSortTypeChange(type: Option) {
    setCoins([]);
    setCurrentPage(1);
    context.setCurrentPage(1);
    context.setSortType(type);
    setSortType(type);
  }

  function handleSparklineSwitchChange(checked: boolean) {
    setCoins([]);
    context.setFetchSparkline(checked);
    setFetchSparkline(checked);
  }

  function getPriceTypeSymbol(code: string) {
    const price = PRICE_TYPES.find((price) => price.code === code);
    return price?.symbol;
  }

  return (
    <>
      {/* Carousel */}
      <Carousel />
      {!isFetching ? (
        <div className="md:container mx-auto p-8">
          {/* Control Container */}
          <div className="md:container bg-white px-2 py-3 my-3 mx-auto rounded-md shadow-lg">
            <div className="w-auto mx-auto flex flex-row flex-wrap md:justify-end sm:justify-start ">
              <svg
                className="w-8 h-8 m-2 flex self-center"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                />
                <title>Sparkline</title>
              </svg>
              <div className="flex self-center m-2 mt-4">
                <label htmlFor="material-switch">
                  <Switch
                    checked={fetchSparkline}
                    onChange={handleSparklineSwitchChange}
                    onColor="#e5e7eb"
                    onHandleColor="#111827"
                    offColor="#e5e7eb"
                    offHandleColor="#111827"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                  />
                </label>
              </div>
              <SortDropdown onChange={handleSortTypeChange} selectedValue={sortType} />
              <PriceTypeDropdown priceTypes={PRICE_TYPES} onChange={handlePriceTypeChange} selectedValue={priceType} />
              {/* <span className="border border-gray-800 bg-gray-800 text-white rounded-md px-2 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-800 focus:outline-none focus:shadow-outline focus:text-white">
                  Sort By
                </span> */}
              <div className="mt-1 mx-2">
                <Pagination handlePageChange={handlePageChange} currentPage={currentPage - 1} collapsed={true} />
              </div>
            </div>
          </div>
          {/* Card Container */}
          <div className="flex flex-row flex-wrap -mx-2">
            {coins.map((coin) => (
              <CurrencyCard {...coin} key={coin.id} priceType={`${getPriceTypeSymbol(priceType.value)} `} />
            ))}
          </div>
          {/* Pagination */}
          <div className="md:container flex justify-center my-10">
            <Pagination handlePageChange={handlePageChange} currentPage={currentPage - 1} collapsed={false} />
          </div>
        </div>
      ) : (
        <CardsSkeleton cardCount={12} />
      )}
      <div>
        <ContactSection />
      </div>
    </>
  );
}
