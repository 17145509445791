import { CoinMarketChartData } from '../../services/Coins/types';

export const getChartConfig = (
  marketChartData: CoinMarketChartData,
  yAxisTitle: string,
  seriesName: string,
  chartTitle: string
) => {
  return {
    series: [
      {
        name: seriesName,
        data: marketChartData?.prices,
      },
    ],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: chartTitle,
        align: 'left',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: new Intl.NumberFormat('en-US').format,
        },
        title: {
          text: yAxisTitle,
        },
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: false,
        y: {
          formatter: new Intl.NumberFormat('en-US').format,
        },
      },
    },
  };
};
