export const API_URL = 'https://api.coingecko.com/api/v3';

export const ORDER = {
  GECKO_ASC: 'gecko_asc',
  GECKO_DESC: 'gecko_desc',
  MARKET_CAP_ASC: 'market_cap_asc',
  MARKET_CAP_DESC: 'market_cap_desc',
  VOLUME_ASC: 'volume_asc',
  VOLUME_DESC: 'volume_desc',
  COIN_NAME_ASC: 'coin_name_asc',
  COIN_NAME_DESC: 'coin_name_desc',
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
  HOUR_24_ASC: 'h24_change_asc',
  HOUR_24_DESC: 'h24_change_desc',
  TRUST_SCORE_DESC: 'trust_score_desc',
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  OPEN_INTEREST_BTC_ASC: 'open_interest_btc_asc',
  OPEN_INTEREST_BTC_DESC: 'open_interest_btc_desc',
  TRADE_VOLUME_24H_BTC_ASC: 'trade_volume_24h_btc_asc',
  TRADE_VOLUME_24H_BTC_DESC: 'trade_volume_24h_btc_desc',
};

export const SORT_TYPES = [
  { label: 'MKT Cap Desc.', value: ORDER.MARKET_CAP_DESC },
  { label: 'MKT Cap Asc.', value: ORDER.MARKET_CAP_ASC },
  { label: 'Trust Score Desc.', value: ORDER.TRUST_SCORE_DESC },
  { label: 'Price Desc.', value: ORDER.PRICE_DESC },
  { label: 'Price Asc.', value: ORDER.PRICE_ASC },
  { label: '24H Change Desc.', value: ORDER.HOUR_24_DESC },
  { label: '24H Change Asc.', value: ORDER.HOUR_24_ASC },
];

export const PRICE_TYPES_WO_CRYPTO = [
  { currency: 'US Dollar', code: 'USD', symbol: '$' },
  { currency: 'Euro', code: 'EUR', symbol: '€' },
  { currency: 'Pounds Sterling', code: 'GBP', symbol: '£' },
  { currency: 'Japanese Yen', code: 'JPY', symbol: '¥' },
  { currency: 'Chinese Yuan', code: 'CNY', symbol: '¥ /元' },
  { currency: 'Australian Dollars', code: 'AUD', symbol: '$' },
  { currency: 'Indian Rupee', code: 'INR', symbol: '₹' },
  { currency: 'Pakistani Rupee', code: 'PKR', symbol: 'Rs.' },
];

export const PRICE_TYPES = [
  ...PRICE_TYPES_WO_CRYPTO,
  { currency: 'Bitcoin', code: 'BTC', symbol: '₿' },
  { currency: 'Ripple', code: 'XRP', symbol: 'XRP' },
  { currency: 'Ethereum', code: 'ETH', symbol: 'Ξ' },
];

export const DAYS_TYPES = [
  { key: '1 Day', value: '1' },
  { key: '1 Week', value: '7' },
  { key: '2 Weeks', value: '14' },
  { key: '1 Month', value: '30' },
  { key: '2 Months', value: '60' },
  { key: '3 Months', value: '90' },
  { key: '1 Year', value: '365' },
];

/*
  This poll time must be set with respect to available network bandwidth and CPU power of the device (react node compute time).
  Otherwise times like 1 or 2 seconds will give weird UX issues. If absolute real time thing is required, either use webassembly to
  compute react node or use a websocket server which pushes updated price to clients. Since this is more of a MVP, the overhead of
  maintaining a real time server is not added as of now.
*/
export const POLL_REFRESH_DATA = 4000; // time in miliseconds
