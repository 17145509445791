import React from 'react';
// import { NavLink, Link } from 'react-router-dom';
import Routes from './routes';
import { CoinContextProvider } from './CoinContext';

function App() {
  return (
    <>
      {/* <div className="md:container bg-white px-6 py-4 my-3 mx-auto shadow rounded-md flex items-center">
        <div className="w-full float-right mx-auto">
          <NavLink
            className="border border-gray-800 bg-gray-800 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-800 focus:outline-none focus:shadow-outline focus:text-white"
            to="/"
          >
            Home
          </NavLink>
          <Link
            className="border border-gray-800 bg-gray-800 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-gray-800 focus:outline-none focus:shadow-outline focus:text-white"
            to="/dashboard"
          >
            Dashboard
          </Link>
        </div>
      </div> */}
      <CoinContextProvider>
        <div>
          <Routes />
        </div>
      </CoinContextProvider>
    </>
  );
}

export default App;
