import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationProps } from './interfaces';

const DashboardPaginate = (props: PaginationProps) => {
  const previous = (
    <div className="h-12 w-12 mr-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-chevron-left w-6 h-6"
      >
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
    </div>
  );

  const next = (
    <div className="h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-chevron-right w-6 h-6"
      >
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
    </div>
  );

  return (
    <ReactPaginate
      previousLabel={previous}
      nextLabel={next}
      breakLabel={'...'}
      pageCount={50}
      initialPage={0}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={props.handlePageChange}
      forcePage={props.currentPage}
      containerClassName={'flex flex-row'}
      pageClassName={`w-12 ${
        props.collapsed ? '' : 'md:flex'
      } justify-center items-center hidden leading-5 transition duration-150 ease-in rounded-full`}
      pageLinkClassName={'outline-none'}
      activeClassName={'bg-gray-800 text-white'}
      breakClassName={`w-12 ${
        props.collapsed ? '' : 'md:flex'
      } justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full`}
      breakLinkClassName={'outline-none'}
      previousLinkClassName={'outline-none'}
      nextLinkClassName={'outline-none'}
      disableInitialCallback={true}
    />
  );
};

export default React.memo(DashboardPaginate);
