import React from 'react';

export interface PriceChangeComparisonProps {
  price_change_percentage_24h: number;
  price_change_percentage_7d: number;
  price_change_percentage_14d: number;
  price_change_percentage_30d: number;
  price_change_percentage_60d: number;
  price_change_percentage_1y: number;
}

interface IGetRow {
  intervalHeading: string;
  intervalTitle: string;
  changeHeading: string;
  changeValue: string | number;
}

const PriceChangeComparison = function (props: PriceChangeComparisonProps) {
  const shouldShowRed = (value: number): boolean => {
    return value >= 0;
  };

  const decreasingValueTextStyle = (value: number): string => {
    return shouldShowRed(value) ? 'text-green-600' : 'text-red-600';
  };

  const getCell = (title: string, value: string | number, textStyle: boolean) => (
    <td
      className={`w-full lg:w-auto pb-3 px-3 pt-8 lg:p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static ${
        textStyle ? decreasingValueTextStyle(value as number) : ''
      }`}
    >
      <span className="lg:hidden absolute top-0 left-0 bg-gray-800 text-white px-2 py-1 text-xs font-bold">
        {title}
      </span>
      {isNaN(value as number) ? value : `${new Intl.NumberFormat('en-US').format(value as number)}%`}
    </td>
  );

  const getRow = ({ intervalHeading, intervalTitle, changeHeading, changeValue }: IGetRow) => (
    <tr
      className={`bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0`}
    >
      {getCell(intervalHeading, intervalTitle, false)}
      {getCell(changeHeading, changeValue, true)}
    </tr>
  );

  return (
    <>
      <div className="flex justify-center mb-12">
        <table className="w-2/3">
          <thead>
            <tr>
              <th className="p-3 font-bold bg-gray-800 text-white border-gray-300 hidden lg:table-cell">
                Time Interval
              </th>
              <th className="p-3 font-bold bg-gray-800 text-white border-gray-300 hidden lg:table-cell">
                Percentage Change
              </th>
            </tr>
          </thead>
          <tbody>
            {getRow({
              intervalHeading: 'Time Interval',
              intervalTitle: '24 Hour',
              changeHeading: 'Change',
              changeValue: props.price_change_percentage_24h,
            })}
            {getRow({
              intervalHeading: 'Time Interval',
              intervalTitle: '7 Days',
              changeHeading: 'Change',
              changeValue: props.price_change_percentage_7d,
            })}
            {getRow({
              intervalHeading: 'Time Interval',
              intervalTitle: '14 Days',
              changeHeading: 'Change',
              changeValue: props.price_change_percentage_14d,
            })}
            {getRow({
              intervalHeading: 'Time Interval',
              intervalTitle: '30 Days',
              changeHeading: 'Change',
              changeValue: props.price_change_percentage_30d,
            })}
            {getRow({
              intervalHeading: 'Time Interval',
              intervalTitle: '60 Days',
              changeHeading: 'Change',
              changeValue: props.price_change_percentage_60d,
            })}
            {getRow({
              intervalHeading: 'Time Interval',
              intervalTitle: '1 Year',
              changeHeading: 'Change',
              changeValue: props.price_change_percentage_1y,
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default React.memo(PriceChangeComparison);
