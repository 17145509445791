import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import coin1 from '../../../assets/1.webp';
import coin2 from '../../../assets/2.webp';
import coin3 from '../../../assets/3.webp';
import coin4 from '../../../assets/4.webp';
import coin5 from '../../../assets/5.webp';
import logo from '../../../assets/logo.png';

import './index.css';

const DashboardCarousel = () => {
  return (
    <div className="md:container mx-auto p-4">
      <div className="flex flex-row justify-center m-4 ">
        <img className="w-32 mx-4" src={logo} alt="Coin786"></img>
        {/* <span className="font-bold text-4xl">COIN786</span> */}
      </div>
      <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} swipeable={true} emulateTouch={true}>
        <div>
          <img src={coin1} alt="coin_1" />
        </div>
        <div>
          <img src={coin2} alt="coin_2" />
        </div>
        <div>
          <img src={coin3} alt="coin_3" />
        </div>
        <div>
          <img src={coin4} alt="coin_4" />
        </div>
        <div>
          <img src={coin5} alt="coin_5" />
        </div>
      </Carousel>
      <div className="justify-center m-14 ">
        <h1 className="font-bold text-2xl text-center">BEST CRYPTOCURRENCY EXCHANGE</h1>
        <hr className="mt-5 mx-auto w-1/2 border-gray-300" />
        <p className="mx-auto mt-2 text-xl text-center">THE BEST PLACE TO PAY, BUY AND SELL WITH DIGITAL CURRENCY</p>
      </div>
    </div>
  );
};

export default DashboardCarousel;
