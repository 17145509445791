import React, { PropsWithChildren, ReactNode } from 'react';

type TCardProps = PropsWithChildren<ReactNode> & {
  cardShadow?: boolean;
  isLoading?: boolean;
  className?: string;
  onCardClick?: () => void;
};

export function Card(props: TCardProps) {
  const cardShadowProperty = () => {
    return props.cardShadow ? 'shadow-xl' : 'border';
  };

  return (
    <div
      onClick={props.onCardClick}
      className={`w-full md:w-1/2 xl:w-1/3 2xl:w-1/4 mb-4 px-2 ${props.isLoading ? 'cursor-pointer' : ''}`}
    >
      <div className={`relative bg-white px-6 py-8 rounded-xl ${cardShadowProperty()} ${props.className}`}>
        {props.children}
      </div>
    </div>
  );
}

export default Card;
