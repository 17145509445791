/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';

const CoinDetailSkeleton = function () {
  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="p-6">
          <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
          <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
          <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
          <div className="flex items-center flex-wrap ">
            <a className="bg-gray-100 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
            <span className="bg-gray-100 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
          </div>
        </div>
      </div>
      <div className="p-6 lg:w-2/3 mx-auto">
        <h2 className="bg-gray-400 animate-pulse h-4 w-1/4 mb-2"></h2>
        <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-gray-500"></h1>
        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-gray-400"></p>
        <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-gray-400"></p>
        <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-gray-400"></p>
        <div className="flex items-center flex-wrap ">
          <a className="bg-gray-400 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
          <span className="bg-gray-400 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
        </div>
      </div>
      <div className="p-6 lg:w-2/3 mx-auto">
        <h2 className="bg-green-200 animate-pulse h-4 w-1/4 mb-2"></h2>
        <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-green-400"></h1>
        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-green-200"></p>
        <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-green-200"></p>
        <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-green-200"></p>
        <div className="flex items-center flex-wrap ">
          <a className="bg-green-100 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
          <span className="bg-green-100 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
        </div>
        <h2 className="bg-green-200 animate-pulse h-4 w-1/4 mb-2"></h2>
        <h1 className="w-1/2 mb-4 h-6 animate-pulse bg-green-400"></h1>
        <p className="leading-relaxed mb-3 w-full h-3 animate-pulse bg-green-200"></p>
        <p className="leading-relaxed mb-3 w-2/3 h-3 animate-pulse bg-green-200"></p>
        <p className="leading-relaxed mb-3 w-1/2 h-3 animate-pulse bg-green-200"></p>
      </div>
    </>
  );
};

export default CoinDetailSkeleton;
