import React from 'react';
import StyledDropdown from '../../components/Dropdown';
import { PriceTypeDropdownProps } from './interfaces';

export function PriceTypeDropdown(props: PriceTypeDropdownProps) {
  const getPriceOptions = () => {
    return props.priceTypes.map((price) => ({
      value: price.code,
      label: price.currency,
    }));
  };

  return (
    <>
      <StyledDropdown
        onChange={props.onChange}
        selectOptions={getPriceOptions()}
        selectedValue={props.selectedValue}
        widthClass="w-52"
      />
    </>
  );
}

export default PriceTypeDropdown;
