import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components/Card/';
import CardTitle from './CurrencyCardHeader';
import CardBody from './CurrencyCardBody';
import CardSparkline from './CardSparkline';
import { CurrencyCardProps } from './interfaces';

export function CurrencyCard(props: CurrencyCardProps) {
  const history = useHistory();

  const shouldShowRed = (value: number): boolean => {
    return value >= 0;
  };

  // const gradientStyle = (value: number): string => {
  //   return shouldShowRed(value)
  //     ? 'bg-gradient-to-t from-green-100 to-transparent'
  //     : 'bg-gradient-to-t from-red-100 to-transparent';
  // };

  const onCardClick = () => {
    history.push(`/coin/${props.id}`);
  };

  return (
    <>
      {/* <Card cardShadow={true} className={gradientStyle(props.priceChangePercentageIn1Hour as number)}> */}
      <Card cardShadow={true} onCardClick={onCardClick} isLoading={props.image ? true : false}>
        <CardTitle
          id={props.id as string}
          image={props.image as string}
          name={props.name as string}
          symbol={props.symbol as string}
        />
        <CardBody
          currentPrice={props.currentPrice as number}
          id={props.id as string}
          marketCapRank={props.marketCapRank as number}
          priceChangePercentageIn1Hour={props.priceChangePercentageIn1Hour as number}
          priceChangePercentageIn24Hours={props.priceChangePercentageIn24Hours as number}
          priceChangePercentageIn7Days={props.priceChangePercentageIn7Days as number}
          priceType={props.priceType}
          shouldShowRed={shouldShowRed}
        />
        <CardSparkline
          id={props.id as string}
          priceChangePercentageIn7Days={props.priceChangePercentageIn7Days as number}
          sparkline={props.sparkline as { price: Array<number> }}
          width={100}
          height={20}
          style={{ fill: 'none' }}
          shouldShowSparklineSpots={false}
          shouldShowReferenceLine={false}
          shouldShowRed={shouldShowRed}
        />
      </Card>
    </>
  );
}

export default CurrencyCard;
