import React from 'react';
import { CurrencyCardHeaderProps } from './interfaces';

const CardTitle = function (props: CurrencyCardHeaderProps) {
  return (
    <div className="flex justify-center mb-3">
      <img src={props.image?.replace('large', 'thumb')} alt={props.symbol} />
      <span className="text-lg self-center mx-2 font-semibold">{props.name}</span>
    </div>
  );
};

export default React.memo(CardTitle);
