import React, { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CoinDetails, Dashboard } from '../containers';

export const Routes = (): ReactElement => {
  return (
    <Switch>
      <Route path="/coin/:id">
        <CoinDetails />
      </Route>
      <Route path="/">
        <Dashboard />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
