import React from 'react';

export interface HeaderStatsProps {
  image: { thumb: string; small: string; large: string };
  symbol: string;
  links: { homepage: Array<string>; blockchain_site: Array<string>; subreddit_url: string };
  name: string;
  market_cap_rank: number;
  current_price: Record<string, number>;
  liquidity_score: number;
  high_24h: Record<string, number>;
  low_24h: Record<string, number>;
  ath: Record<string, number>;
  atl: Record<string, number>;
  sentiment_votes_up_percentage: number;
  sentiment_votes_down_percentage: number;
  circulating_supply: number;
  priceType: string;
}

const HeaderStats = function (props: HeaderStatsProps) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: props.priceType.toLowerCase(),
    minimumFractionDigits: 2,
  });

  return (
    <>
      <div className="flex flex-row -mx-2">
        <div className=" w-full">
          <div className="flex justify-center mb-3">
            <img src={props.image.small} alt={props.symbol} />
            <h1 className="sm:text-4xl text-3xl font-medium title-font text-gray-900 hover:underline self-center mx-2">
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href={props.links.homepage[0]} target="_blank">
                {props.name}
              </a>
            </h1>
          </div>
          <div className="text-center">
            <span> Rank #{props.market_cap_rank}</span>
          </div>
          <div className="lg:w-2/3 mx-auto">
            <section className="text-gray-600 body-font">
              <div className="container px-5 py-12 mx-auto">
                <div className="flex flex-wrap -m-4 text-center">
                  <div className="p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4 w-full">
                    <div className="border-2 border-gray-100 rounded-lg shadow-lg px-4 py-6">
                      <h2 className="title-font font-medium text-xl text-gray-900">
                        {formatter.format(props.current_price[props.priceType.toLowerCase()] as number)}
                      </h2>
                      <p className="leading-relaxed">Price</p>
                    </div>
                  </div>
                  <div className="p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4 w-full">
                    <div className="border-2 border-gray-100 rounded-lg shadow-lg px-4 py-6">
                      <h2 className="title-font font-medium text-xl text-gray-900">{props.liquidity_score}</h2>
                      <p className="leading-relaxed">Liquidity*</p>
                    </div>
                  </div>
                  <div className="p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4 w-full">
                    <div className="border-2 border-gray-100 rounded-lg shadow-lg px-4 py-6">
                      <h2 className="title-font font-medium text-xl text-gray-900">
                        {formatter.format(props.low_24h[props.priceType.toLowerCase()] as number)}
                      </h2>
                      <p className="leading-relaxed">24h Low</p>
                    </div>
                  </div>
                  <div className="p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4 w-full">
                    <div className="border-2 border-gray-100 rounded-lg shadow-lg px-4 py-6">
                      <h2 className="title-font font-medium text-xl text-gray-900">
                        {formatter.format(props.high_24h[props.priceType.toLowerCase()] as number)}
                      </h2>
                      <p className="leading-relaxed">24h High</p>
                    </div>
                  </div>
                  <div className="p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4 w-full">
                    <div className="border-2 border-gray-100 rounded-lg shadow-lg px-4 py-6">
                      <h2 className="title-font font-medium text-xl text-gray-900">
                        {formatter.format(props.ath[props.priceType.toLowerCase()] as number)}
                      </h2>
                      <p className="leading-relaxed">Alltime High</p>
                    </div>
                  </div>
                  <div className="p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4 w-full">
                    <div className="border-2 border-gray-100 rounded-lg shadow-lg px-4 py-6">
                      <h2 className="title-font font-medium text-xl text-gray-900">
                        {formatter.format(props.atl[props.priceType.toLowerCase()] as number)}
                      </h2>
                      <p className="leading-relaxed">Alltime Low</p>
                    </div>
                  </div>
                  <div className="p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4 w-full">
                    <div className="border-2 border-gray-100 rounded-lg shadow-lg px-4 py-6">
                      <h2 className="title-font font-medium text-xl text-gray-900">
                        {props.sentiment_votes_up_percentage}%
                      </h2>
                      <p className="leading-relaxed">Vote up</p>
                    </div>
                  </div>
                  <div className="p-4 md:w-1/2 xl:w-1/3 2xl:w-1/4 w-full">
                    <div className="border-2 border-gray-100 rounded-lg shadow-lg px-4 py-6">
                      <h2 className="title-font font-medium text-xl text-gray-900">
                        {props.sentiment_votes_down_percentage}%
                      </h2>
                      <p className="leading-relaxed">Vote down</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-start mt-3">
                  <em className="lg:w-2/3 text-left text-sm text-gray-900">*liquidity score is out of 100</em>
                  <em className="lg:w-2/3 text-left text-sm text-gray-900">**votes denote community sentiment</em>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="flex mb-3 text-left">
        <p className="lg:w-2/3 text-base font-normal mx-auto leading-relaxed">
          {props.name} <strong>rate</strong> for today is{' '}
          <strong>{formatter.format(props.current_price[props.priceType.toLowerCase()] as number)}</strong>. It has a
          current circulating supply of {new Intl.NumberFormat('en-US').format(props.circulating_supply as number)}{' '}
          coins. Price change percentage with time intervals is given below:
        </p>
      </div>
    </>
  );
};

export default React.memo(HeaderStats);
