import React from 'react';
import StyledDropdown from '../../components/Dropdown';
import { SortDropdownProps } from './interfaces';
import { SORT_TYPES } from '../../utils/constants';

export function SortDropdown(props: SortDropdownProps) {
  const getSortOptions = () => {
    return SORT_TYPES.map((type) => ({
      value: type.value,
      label: type.label,
    }));
  };

  return (
    <>
      {/* <span className="text-gray-800 text-lg font-medium rounded-md py-2 m-2">Sort By:</span> */}
      <StyledDropdown
        onChange={props.onChange}
        selectOptions={getSortOptions()}
        selectedValue={props.selectedValue}
        widthClass="w-52"
      />
    </>
  );
}

export default SortDropdown;
