import { CoinMarketData, CoinMarketDataFromAPI } from './types';

export const transformCoinsMarketData = (coins: CoinMarketDataFromAPI[]): CoinMarketData[] => {
  return coins.map((coin) => ({
    allTimeHigh: coin.ath,
    allTimeHighChangePercentage: coin.ath_change_percentage,
    allTimeHighDate: coin.ath_date,
    allTimeLow: coin.atl,
    allTimeLowChangePercentage: coin.atl_change_percentage,
    allTimeLowDate: coin.atl_date,
    circulatingSupply: coin.circulating_supply,
    currentPrice: coin.current_price,
    fullyDilutedValuation: coin.fully_diluted_valuation,
    highestIn24Hours: coin.high_24h,
    id: coin.id,
    image: coin.image,
    lastUpdated: coin.last_updated,
    lowestIn24Hours: coin.low_24h,
    marketCap: coin.market_cap,
    marketCapChangeIn24Hours: coin.market_cap_change_24h,
    marketCapChangePercentageIn24Hours: coin.market_cap_change_percentage_24h,
    marketCapRank: coin.market_cap_rank,
    maxSupply: coin.max_supply,
    name: coin.name,
    priceChangeIn24Hours: coin.price_change_24h,
    priceChangePercentageIn1Hour: coin.price_change_percentage_1h_in_currency,
    priceChangePercentageIn24Hours: coin.price_change_percentage_24h_in_currency,
    priceChangePercentageIn7Days: coin.price_change_percentage_7d_in_currency,
    roi: coin.roi,
    sparkline: coin.sparkline_in_7d,
    symbol: coin.symbol,
    totalSupply: coin.total_supply,
    totalVolume: coin.total_volume,
  }));
};
