import React from 'react';
import { CurrencyCardBodyProps } from './interfaces';

const CardBody = React.memo<CurrencyCardBodyProps>((props) => {
  const shouldShowRed = (value: number): boolean => {
    return value >= 0;
  };

  const decreasingValueTextStyle = (value: number): string => {
    return shouldShowRed(value) ? 'text-green-600' : 'text-red-600';
  };

  return (
    <>
      <hr className="mb-3 border-black" />
      <div className="flex flew-row justify-between mb-3">
        <span>Market Cap Rank</span>
        <span># {props.marketCapRank}</span>
      </div>
      <hr className="mb-3 border-black" />
      <div className="flex flew-row justify-between mb-3 text-lg font-semibold">
        <span>Price</span>
        <span>
          {props.priceType}
          {props.currentPrice?.toFixed(2)}
        </span>
      </div>
      <div
        className={`flex flew-row justify-between mb-2 ${decreasingValueTextStyle(
          props.priceChangePercentageIn1Hour as number
        )}`}
      >
        <span>change in 1 hour</span>
        <span>{props.priceChangePercentageIn1Hour?.toFixed(8)} %</span>
      </div>
      <div
        className={`flex flew-row justify-between mb-2 ${decreasingValueTextStyle(
          props.priceChangePercentageIn24Hours as number
        )}`}
      >
        <span>change in 24 hours</span>
        <span>{props.priceChangePercentageIn24Hours?.toFixed(5)} %</span>
      </div>
      <div
        className={`flex  flew-row justify-between mb-2 ${decreasingValueTextStyle(
          props.priceChangePercentageIn7Days as number
        )}`}
      >
        <span>change in 7 days</span>
        <span>{props.priceChangePercentageIn7Days?.toFixed(2)} %</span>
      </div>
    </>
  );
});

export default CardBody;
