import { AxiosResponse } from 'axios';

import apiClient from '../../utils/ApiClient';
import { CoinMarketDataFromAPI, CoinCurrentDataFromAPI, CoinMarketChartData } from './types';
import { transformCoinsMarketData } from './transformer';

export const getCoinsMarketData = async (
  currentPage: number,
  currency: string,
  sortBy: string,
  fetchSparkline: boolean
) => {
  const params = {
    order: sortBy,
    per_page: 50,
    page: currentPage,
    sparkline: fetchSparkline,
    vs_currency: currency,
    price_change_percentage: '1h,24h,7d',
    timestamp: new Date().getTime(),
  };
  const { data: CoinMarketData }: AxiosResponse<CoinMarketDataFromAPI[]> = await apiClient.get('/coins/markets', {
    params,
  });
  return transformCoinsMarketData(CoinMarketData);
};

export const getCoinCurrentData = async (coinId: string) => {
  const params = {
    // sparkline: true,
    developer_data: false,
    community_data: false,
    market_data: true,
    localization: false,
    tickers: true,
    timestamp: new Date().getTime(),
  };
  const { data: CoinCurrentData }: AxiosResponse<CoinCurrentDataFromAPI> = await apiClient.get(`/coins/${coinId}`, {
    params,
  });
  return CoinCurrentData;
};

export const getMarketChartData = async (coinId: string, currency: string, days: number) => {
  const params = {
    vs_currency: currency,
    days: days,
    timestamp: new Date().getTime(),
  };
  const { data: MarketChartData }: AxiosResponse<CoinMarketChartData> = await apiClient.get(
    `coins/${coinId}/market_chart`,
    {
      params,
    }
  );

  return MarketChartData;
};
