import React from 'react';
import StyledDropdown from '../../components/Dropdown';
import { DayDropdownProps } from './interfaces';
import { DAYS_TYPES } from '../../utils/constants';

export function DayDropdown(props: DayDropdownProps) {
  const getDayOptions = () => {
    return DAYS_TYPES.map((day) => ({
      value: day.value,
      label: day.key,
    }));
  };

  return (
    <>
      <StyledDropdown
        onChange={props.onChange}
        selectOptions={getDayOptions()}
        selectedValue={props.selectedValue}
        widthClass="w-52"
      />
    </>
  );
}

export default DayDropdown;
