/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Option } from 'react-dropdown';
import Chart from 'react-apexcharts';

import { getCoinCurrentData, getMarketChartData } from '../../services';
import { CoinCurrentDataFromAPI, CoinMarketChartData, Tickers } from '../../services/Coins/types';
import HeaderStats from './HeaderStats';
import PriceChangeComparison from './PriceChangeComparison';
import CoinDetailSkeleton from './CoinDetailSkeleton';
// import { useInterval } from '../../hooks';
import { PRICE_TYPES_WO_CRYPTO, DAYS_TYPES } from '../../utils/constants';
import PriceTypeDropdown from '../../components/PriceTypeDropdown';
import { getChartConfig } from './Config';
import DayDropdown from '../../components/DayDropdown';

export function CoinDetails() {
  let { id } = useParams<{ id: string }>();
  const defaultPrice = { label: PRICE_TYPES_WO_CRYPTO[0].currency, value: PRICE_TYPES_WO_CRYPTO[0].code };
  const defaultPeriod = { label: DAYS_TYPES[0].key, value: DAYS_TYPES[0].value };
  const [priceType, setPriceType] = useState<Option>(defaultPrice);
  const [period, setPeriod] = useState<Option>(defaultPeriod);
  const [periodLoading, setPeriodLoading] = useState<boolean>(false);
  const [priceConfig, setPriceConfig] = useState<any>();

  const [coinData, setCoinData] = useState<CoinCurrentDataFromAPI>();
  const [marketChartData, setMarketChartData] = useState<CoinMarketChartData>();

  // useInterval(() => fetchData(id), POLL_REFRESH_DATA);

  useEffect(() => {
    let isMounted = true;
    getCoinCurrentData(id).then((data) => {
      if (isMounted) {
        setCoinData(data);
      }
    });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    setPeriodLoading(true);
    getMarketChartData(id, priceType.value.toLowerCase(), parseInt(period.value, 10)).then((marketChart) => {
      if (isMounted) {
        setMarketChartData(marketChart);
        setPeriodLoading(false);
      }
    });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceType, period]);

  useEffect(() => {
    const priceChart = getChartConfig(
      marketChartData as CoinMarketChartData,
      `Price (${priceType.value})`,
      'Coin Price',
      'Coin Price Movement'
    );
    setPriceConfig(priceChart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketChartData]);

  function handlePriceTypeChange(type: Option) {
    setPriceType(type);
  }

  function handlePeriodChange(type: Option) {
    console.log({ type });
    setPeriod(type);
  }

  function tickerInfo() {
    const relevantTickers = coinData?.tickers.filter((ticker) => ticker.target === 'USD') as Tickers;

    return relevantTickers?.length > 0 ? (
      <div className="flex flex-col justify-center mb-12">
        <div className="lg:w-2/3 lg:mx-auto rounded-lg mb-3">
          <div className="flex flex-wrap">
            {relevantTickers?.map((ticker) => (
              <a className="mt-5 mx-2" href={ticker.trade_url} target="_blank" rel="noreferrer">
                <div className="flex">
                  <button className="px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-gray-900 rounded-full shadow ripple waves-light hover:shadow-lg focus:outline-none hover:bg-gray-900">
                    {ticker.market.name}
                  </button>
                  <svg
                    className="w-6 h-6 self-center mx-1"
                    fill="none"
                    stroke={ticker.trust_score}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                    />
                  </svg>
                </div>
              </a>
            ))}
          </div>
        </div>
        <em className="lg:w-2/3 mx-auto text-sm text-gray-900">Icon shows the trust score of exchange</em>
      </div>
    ) : (
      <></>
    );
  }

  return (
    <>
      <div>
        <div className="container w-full mx-auto p-8">
          {coinData ? (
            <>
              <div className="flex flex-row justify-between bg-white py-3 my-3 mb-24 mx-auto rounded-md lg:w-2/3">
                <div className="w-auto flex flex-row flex-wrap sm:justify-start ">
                  <Link className="self-center" to="/">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="w-auto flex flex-row flex-wrap sm:justify-end ">
                  <PriceTypeDropdown
                    priceTypes={PRICE_TYPES_WO_CRYPTO}
                    onChange={handlePriceTypeChange}
                    selectedValue={priceType}
                  />
                </div>
              </div>
              <HeaderStats
                ath={coinData?.market_data.ath as Record<string, number>}
                atl={coinData?.market_data.atl as Record<string, number>}
                circulating_supply={coinData?.market_data.circulating_supply as number}
                current_price={coinData?.market_data.current_price as Record<string, number>}
                high_24h={coinData?.market_data.high_24h as Record<string, number>}
                low_24h={coinData?.market_data.low_24h as Record<string, number>}
                image={
                  coinData?.image as {
                    thumb: string;
                    small: string;
                    large: string;
                  }
                }
                links={
                  coinData?.links as {
                    homepage: string[];
                    blockchain_site: string[];
                    subreddit_url: string;
                  }
                }
                liquidity_score={coinData?.liquidity_score as number}
                market_cap_rank={coinData?.market_cap_rank as number}
                name={coinData?.name as string}
                sentiment_votes_down_percentage={coinData?.sentiment_votes_down_percentage as number}
                sentiment_votes_up_percentage={coinData?.sentiment_votes_up_percentage as number}
                symbol={coinData?.symbol as string}
                key={coinData?.id}
                priceType={priceType.value}
              />
              {/* Price Change table against time interval */}
              <PriceChangeComparison
                price_change_percentage_24h={coinData?.market_data.price_change_percentage_24h as number}
                price_change_percentage_7d={coinData?.market_data.price_change_percentage_7d as number}
                price_change_percentage_14d={coinData?.market_data.price_change_percentage_14d as number}
                price_change_percentage_30d={coinData?.market_data.price_change_percentage_30d as number}
                price_change_percentage_60d={coinData?.market_data.price_change_percentage_60d as number}
                price_change_percentage_1y={coinData?.market_data.price_change_percentage_1y as number}
              />
              {/* Charts */}
              {periodLoading ? (
                <div className="flex flex-row justify-end lg:w-2/3 mx-auto">
                  <div className="p-6">
                    <div className="flex items-center flex-wrap ">
                      <a className="bg-gray-100 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
                      <span className="bg-gray-100 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
                    </div>
                    <div className="flex items-center flex-wrap ">
                      <a className="bg-gray-100 h-4 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0"></a>
                      <span className="bg-gray-100 w-16 mt-2 h-4 animate-pulse mr-3 px-2 inline-flex items-center ml-auto leading-none text-sm pr-5 py-1"></span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row justify-between bg-white py-3 my-3 mb-3 mx-auto rounded-md lg:w-2/3">
                  <div className="w-auto flex flex-row flex-wrap sm:justify-start "></div>
                  <div className="w-auto flex flex-row flex-wrap sm:justify-end ">
                    <DayDropdown onChange={handlePeriodChange} selectedValue={period} />
                  </div>
                </div>
              )}
              <div className="flex flex-col justify-center mb-12">
                <div className="lg:w-2/3 lg:mx-auto shadow-lg rounded-lg mb-3">
                  <Chart options={priceConfig.options} series={priceConfig.series} type="area" height={550} />
                </div>
                <em className="lg:w-2/3 mx-auto text-sm text-gray-900">
                  *Minutely data will be used for duration within 1 day, Hourly data will be used for duration between 1
                  day and 90 days, Daily data will be used for duration above 90 days.
                </em>
              </div>
              {
                tickerInfo()
                /* <div className="flex flex-col justify-center mb-12">
                <div className="lg:w-2/3 lg:mx-auto rounded-lg mb-3">{tickerInfo()}</div>
                <em className="lg:w-2/3 mx-auto text-sm text-gray-900">Green icon the trust score of the exchange</em>
              </div> */
              }
              {/* 7 day sparkline */}
              {/* <div className="flex flex-col justify-center mb-12">
                <h1 className="lg:w-2/3 mx-auto text-md lg:text-xl font-semibold title-font text-gray-900 mb-3">
                  Last 7 day price trend:
                </h1>
                <div className="w-full lg:w-2/3 mx-auto border border-gray-100 shadow-lg rounded-lg mb-2">
                  <CardSparkline
                    id={id}
                    priceChangePercentageIn7Days={coinData?.market_data.price_change_percentage_7d as number}
                    sparkline={coinData?.market_data.sparkline_7d as { price: number[] }}
                    key={id}
                    width={300}
                    height={150}
                    style={{}}
                    shouldShowReferenceLine={true}
                    shouldShowSparklineSpots={true}
                    shouldShowRed={shouldShowRed}
                  />
                </div>
                <em className="lg:w-2/3 mx-auto text-sm text-gray-900">*reference line denotes the median</em>
              </div> */}
              {/* Description of coin */}
              <div className="flex flex-col text-left w-full">
                <h1 className="lg:w-2/3 mx-auto text-lg lg:text-xl font-semibold title-font text-gray-900 mb-3">
                  What is {coinData?.name}?
                </h1>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                  <div dangerouslySetInnerHTML={{ __html: coinData?.description['en'] as string }}></div>
                </p>
              </div>
            </>
          ) : (
            <CoinDetailSkeleton />
          )}
        </div>
      </div>
    </>
  );
}
