import React from 'react';
import Dropdown, { Group, Option } from 'react-dropdown';
import 'react-dropdown/style.css';
import { StyledDropdownProps } from './interfaces';

export function StyledDropdown(props: StyledDropdownProps) {
  const optionClassName =
    'block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg md:mt-0 text-gray-800 hover:text-white focus:text-white hover:bg-gray-800 focus:bg-gray-800 focus:outline-none focus:shadow-outline';

  const dropDownOptions = () => {
    const options: Array<Group | Option> = props.selectOptions.map((option) => ({
      value: option.value,
      label: option.label,
      className: optionClassName,
    }));

    return options;
  };

  return (
    <Dropdown
      className={`m-2 ${props.widthClass}`}
      controlClassName="border-none bg-gray-800 text-white rounded-lg shadow-md"
      // add scrollbar-thumb-gray-200 to show scrollbar
      menuClassName="scrollbar-thin border-none bg-white text-gray-800 rounded-lg shadow-lg"
      placeholderClassName="text-center"
      options={dropDownOptions()}
      onChange={props.onChange}
      value={props.selectedValue}
      placeholder="Select an option"
    />
  );
}

export default StyledDropdown;
